@import "./colors.less";

// >brief
.brief-about {
  .content {
    padding: 2rem 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: @white;
    font-size: 1rem;
    .logo-container {
      max-width: 90px;
      img {
        display: block;
        max-width: 100%;
      }
    }
    p {
      flex: 1;
      margin: 0 0 0 1.75rem;
      color: inherit;
    }
  }
}

// >values
.values-about {
  margin: 4rem 0 0;
  margin-left: auto;
  margin-right: auto;
  @media screen and(min-width:1200px) {
    margin-left: auto;
    margin-right: auto;
  }
  & > .content {
    padding-left: 1rem;
    padding-right: 1rem;
    font-size: 1rem;
    h3 {
      margin: 0;
      font-size: 2rem;
      font-weight: 600;
      text-align: center;
      color: @primary;
      @media screen and(min-width:1200px) {
        font-size: 2.5rem;
      }
    }
    p {
      margin: 1.5rem 0 0;
      color: @black70;
      font-size: inherit;
      text-align: center;
      @media screen and(min-width:1200px) {
        margin-top: 0.5rem;
      }
    }
    .item-container {
      margin: 3rem 0 0;
      display: flex;
      overflow-x: auto;
      padding-bottom: 0.5rem;
      @media screen and(min-width:768px) {
        padding-bottom: 0;
      }
      &::-webkit-scrollbar {
        height: 0.5rem;
      }
      &::-webkit-scrollbar-thumb {
        background-color: @primary;
        border-radius: 0.25rem;
      }
    }
  }
  .item-values {
    margin-left: auto;
    margin-right: auto;
    max-width: 340px;
    position: relative;
    overflow: hidden;
    flex: 0 0 65%;
    padding: 2rem 1rem;
    display: flex;
    flex-flow: column;
    background-color: #009c8e;
    border-radius: 0.75rem;
    @media screen and(min-width: 768px) {
      flex: 1;
    }
    @media screen and(min-width: 1200px) {
      padding: 3rem 1rem;
    }
    &:not(&:nth-child(1)) {
      margin-left: 1rem;
      // @media screen and(min-width: 1200px) {
      //   margin-left: 2rem;
      // }
    }
    & > span {
      position: absolute;
      content: "";
      top: 0;
      left: 50%;
      width: 120%;
      padding-bottom: calc(120% - calc(3rem * 2));
      background: transparent;
      border: 3rem solid #00978a;
      transform: translateX(-50%) translateY(-10%);
      border-radius: 50%;
      z-index: 0;
      @media screen and(min-width: 1200px) {
        width: 110%;
        padding-bottom: calc(110% - calc(3rem * 2));
      }
    }

    .icon-container {
      position: relative;
      z-index: 1;
      max-width: 6rem;
      margin: auto;
    }
    .content {
      position: relative;
      z-index: 1;
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      color: @white;
      p {
        margin: 1.5rem 0 0;
        font-size: 1rem;
        font-weight: 600;
        color: inherit;
        text-align: center;
        @media screen and(min-width: 1200px) {
          font-size: 20px;
        }
      }
    }
  }
}

// >goals
.goals-about {
  margin: 4rem 0 0;
  @media screen and(min-width:768px) {
    margin-top: 3rem;
  }
  & > div {
    padding-left: 1rem;
    padding-right: 1rem;
    @media screen and (min-width: 1200px) {
      padding-left: 0;
      padding-right: 0;
    }
  }
  .items-goals {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    @media screen and(min-width:768px) {
      max-width: 80%;
      margin: auto;
    }
    @media screen and(min-width: 1200px) {
      max-width: unset;
      justify-content: space-between;
    }

    &:not(&:nth-child(1)) {
      margin: 2.5rem 0 0;
      @media screen and(min-width:768px) {
        margin-left: auto;
        margin-right: auto;
      }
    }
    h3 {
      width: 100%;
      margin: 0 0 1.5rem;
      font-size: 1.75rem;
      font-weight: 600;
      text-align: center;
      @media screen and(min-width: 1200px) {
        font-size: 1.75rem;
        text-align: left;
      }
    }
    & > h3 {
      @media screen and(min-width: 1200px) {
        display: none;
      }
    }
    .img-container {
      max-width: 120px;
      @media screen and(min-width: 1200px) {
        max-width: unset;
        width: 45%;
      }
      img {
        display: block;
        width: 100%;
        @media screen and(min-width: 1200px) {
          width: 250px;
          margin: auto;
        }
      }
    }
    .content-container {
      flex: 1;
      margin-left: 1.5rem;
      @media screen and(min-width: 1200px) {
        flex: unset;
        margin: 0;
        width: 50%;
      }
      & > h3 {
        display: none;
        @media screen and(min-width: 1200px) {
          display: block;
        }
      }
      .content {
        color: @black70;
        font-size: 1rem;
        & > * {
          margin: 0;
          &:not(&:nth-child(1)) {
            margin: 1rem 0 0;
          }
        }
        & > ul,
        & > ol {
          padding-left: 1rem;
          li {
            // margin: 0.25rem 0 0;
            margin: 0;
            &::marker {
              color: inherit;
            }
          }
        }
      }
    }
  }
}

// >teams
.teams-about {
  margin: 4rem 0 0;
  // min-height: 2000px;
  // height: 2000px;
  @media screen and(min-width:768px) {
    margin-top: 3rem;
  }
  & > .content {
    position: sticky;
    top: 0;
    overflow: visible;
    padding-left: 1rem;
    padding-right: 1rem;
    font-size: 1rem;
    h3 {
      margin: 0;
      color: @primary;
      font-size: 1.75rem;
      font-weight: 600;
      text-align: center;
      @media screen and (min-width: 1200px) {
        font-size: 2.5rem;
      }
    }
    p {
      margin: 0.5rem 0 0;
      color: @black70;
      text-align: center;
    }
    .slider-teams {
      position: relative;
      margin: 2.5rem 0 0;
      .slider-container {
        position: sticky;
        top: 0;
        z-index: 1;
        .pagination-container {
          visibility: hidden;
        }
        .content-teams {
          display: flex;
          flex-flow: column;
          & > button {
            display: none;
          }
          .slick-list {
            order: 2;
            margin: 2rem 0 0;
          }
        }
        .ava-teams {
          opacity: 1;
          max-width: 600px;
          margin: auto;

          .slick-list {
            .slick-slide {
              width: 100%;
              & > div {
                padding: 0 0.5rem;
              }
              &.slick-current {
                .img-container {
                  filter: grayscale(0);
                }
              }
              .img-container {
                filter: grayscale(95%);
                padding-bottom: 100%;
                overflow: hidden;
                border-radius: 50%;
                border: 1px solid #eaeaea;
                img {
                  width: 100%;
                  height: 100%;
                  position: absolute;
                  top: 0;
                  left: 0;
                  right: 0;
                  bottom: 0;
                  margin: auto;
                  transform: scale(1.2) translateY(15%);
                }
              }
            }
          }
        }
      }
    }
    .item-teams {
      display: flex;
      align-items: center;

      & > .img-container {
        display: none;
        @media screen and (min-width: 768px) {
          display: block;
          width: 45%;
        }
        img {
          display: block;
          width: 100%;
        }
      }
      & > .content {
        font-size: 1rem;
        flex: 1;
        color: @black70;
        @media screen and (min-width: 768px) {
          margin: 0 0 0 1.5rem;
        }
        h4 {
          margin: 0;
          font-size: 1.5rem;
          color: @primary;
          font-weight: 600;
          text-align: center;
          @media screen and (min-width: 1200px) {
            font-size: 2.5rem;
            text-align: left;
          }
        }
        h5 {
          margin: 0.5rem 0 0;
          font-weight: 600;
          color: inherit;
          font-size: inherit;
          text-align: center;
          @media screen and (min-width: 1200px) {
            font-size: 1.25rem;
            text-align: left;
          }
        }
        .desc {
          margin: 1.5rem 0 0;
          color: @black70;
          font-size: inherit;
          & > * {
            font-size: inherit;
            text-align: left;
            margin: 0;
            &:not(&:nth-child(1)) {
              margin: 1rem 0 0;
            }
          }
        }
      }
    }
  }
}
