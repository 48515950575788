.career {
  padding: 0;
}

.interested {
  .content {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 1;
    font-size: 1rem;
    color: @white;
    h3 {
      margin: 0;
      font-size: 1rem;
      color: inherit;
      font-weight: 600;
      text-align: center;
      @media screen and (min-width: 768px) {
        font-size: 1.25rem;
      }
      @media screen and (min-width: 1200px) {
        font-size: 1.75rem;
      }
      span {
        display: block;
      }
    }
    p,
    a {
      margin: 1rem 0 0;
      color: inherit;
      text-align: center;
    }
    a {
      font-weight: 600;
    }
  }
}

.career-list-container {
  // margin: 2rem 0 0;
  // padding: 0 1rem;
  & > h4 {
    margin: 0 0 2rem;
    color: inherit;
    font-weight: 600;
    font-size: 1.25rem;
    text-align: center;
    @media screen and (min-width: 768px) {
      text-align: left;
    }
    @media screen and (min-width: 1200px) {
      font-size: 1.5rem;
    }
  }
  .career-list {
    .career-item {
      color: @black70;
      font-size: 1rem;
      h3 {
        margin: 0;
        font-size: 1.25rem;
        color: @primary;
        font-weight: 600;
        @media screen and (min-width: 1200px) {
          font-size: 1.75rem;
        }
      }
      p {
        cursor: pointer;
        margin: 1rem 0 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        & > span {
          margin-left: 1.5rem;
          width: 1rem;
          height: 1rem;
          @media screen and (min-width: 768px) {
            display: none;
          }
        }
        &:hover {
          color: #0b998e;
        }
      }
    }
  }
}

.career-detail-modal {
  .content {
    padding: 4rem 1rem 1rem;
    & > h3 {
      margin: 0;
      color: @primary;
      font-weight: 600;
      font-size: 1.25rem;
      text-align: center;
      @media screen and (min-width: 1200px) {
        font-size: 1.5rem;
      }
    }
    & > h4 {
      margin: 2rem 0 0.5rem;
    }
    & > div {
      & > * {
        color: @black70;
        margin: 0;
        &:not(&:nth-child(1)) {
          margin: 1rem 0 0;
        }
      }
      & > ul,
      & > ol {
        padding-left: 1rem;
        li {
          margin: 0.25rem 0 0;
          &::marker {
            color: @primary;
          }
        }
      }
    }
  }
}
