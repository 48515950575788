@import "./colors.less";

.project-layout-content {
  min-height: 280px;
  padding: 24px 0.25rem;
  margin: 0 auto;
  @media screen and (min-width: 576px) {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

.projects {
  padding: 0;
}

.platform-tag-filter {
  background: @white;
  overflow: hidden;
  .content {
    padding-top: 0;
    min-height: unset;
    padding-left: 1rem;
    padding-right: 1rem;
    @media screen and(min-width: 1200px) {
      padding-top: 24px;
      padding-left: 0;
      padding-right: 0;
    }
    .item-container {
      display: flex;
      flex-wrap: wrap;
      gap: 0.5rem;
      flex-flow: row;
      overflow-x: auto;
      padding-bottom: 0.5rem;
      &::-webkit-scrollbar {
        height: 0.5rem;
      }
      &::-webkit-scrollbar-thumb {
        background-color: @primary;
        border-radius: 0.25rem;
      }
      @media screen and(min-width: 1200px) {
        padding-bottom: 0;
        flex-flow: row wrap;
        justify-content: center;
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
}
.platform-tag-filter-item {
  cursor: pointer;
  border: 2px solid @primary;
  box-sizing: border-box;
  border-radius: 0.5rem;
  padding: 0.25rem 1rem;
  color: @primary;
  background: @white;
  white-space: nowrap;
  // &:not(&:nth-child(1)) {
  //   margin-left: 0.5rem;
  // }
  &.active {
    background: @primary;
    color: @white;
  }
}

// >project-list
.project-list {
  margin: 2rem 0 0;
  background: @white;
  & > .content {
    padding-left: 1rem;
    padding-right: 1rem;
    min-height: unset;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    @media screen and(min-width: 1200px) {
      padding-left: 0;
      padding-right: 0;
    }
    .item-container {
      width: 100%;
    }
    .btn-more {
      border: 2px solid @primary;
      color: @primary;
      & > * {
        font-weight: 600;
      }
    }
    .null-content {
      text-align: center;
      font-size: 1rem;
      color: @black70;
      .block {
        display: block;
      }
      .font-bold {
        font-weight: 600;
        color: @primary;
      }
    }
  }
}

// >project-item
.project-item {
  // width: calc(50% - 0.25rem);
  // @media screen and (min-width: 768px) {
  //   width: calc(33.33% - 0.5rem);
  // }
  //   margin: 0.125rem;
  cursor: pointer;
  overflow: hidden;
  overflow: hidden;
  border-radius: 1rem;
  transition: 0.3s;
  box-shadow: rgb(26 52 79 / 10%) 0px 2px 12px 0px;
  &:hover {
    transition: 0.3s;
    box-shadow: rgb(26 52 79 / 20%) 0px 2px 8px 0px;
  }
  & > .content {
    padding: 1rem;
    text-align: left;
    font-weight: 600;
    &:hover {
      box-shadow: rgb(26 52 79 / 40%) 0px 2px 8px 0px;
    }
    @media screen and (min-width: 768px) {
      padding: 2rem;
    }
    .title {
      margin: 0;
      font-size: 1.5rem;
      text-align: inherit;
      font-weight: inherit;
      @media screen and (min-width: 768px) {
        font-size: 1.5rem;
      }
      @media screen and (min-width: 1200px) {
        font-size: 2.5rem;
      }
    }
    .subtitle {
      margin: 0.25rem 0 0;
      font-size: 0.75rem;
      line-height: 1rem;
      text-align: inherit;
      font-weight: 400;
      @media screen and (min-width: 1200px) {
        font-size: 1rem;
        line-height: 1.25rem;
      }
    }
    .img-container {
      margin: 2rem 0 0;
      display: flex;
      justify-content: center;
      img {
        max-width: 100%;
        max-height: 100%;
      }
    }
  }
}

// >modal-project-selected
.modal-project-selected {
  border-radius: 1rem;
  overflow: hidden;
  width: 100% !important;
  @media screen and (min-width: 576px) {
    width: 60% !important;
  }
  .content-container {
    padding: 4rem 2rem 2rem;
    display: flex;
    flex-flow: column;
    align-items: center;
    text-align: center;
    h3,
    h4 {
      text-align: inherit;
    }
    h3.title {
      font-size: 2.25rem;
      line-height: 2.75rem;
      font-weight: 600;
      margin: 0;
      @media screen and (min-width: 1200px) {
        font-size: 3rem;
        line-height: 3.5rem;
      }
    }
    h4.subtitle {
      font-size: 1rem;
      margin: 0.5rem 0 0;
      color: inherit;
    }
    .img-container {
      margin: 4rem 0 0;
      //   transform:  translateY(50%);
      //   margin-bottom: -50%;
      img {
        margin-top: -50%;
        transform: translateY(50%);
        max-height: 450px;
      }
    }
  }

  .detail-content {
    margin: 8rem 0 0;
    padding: 2rem 2rem 4rem;
    color: #404041;
    .available-btn {
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      align-items: center;
      gap: 6px;
      & > button {
        display: flex;
        flex-flow: row wrap;
        border: 1px solid #404041;
        border-radius: 0.25rem;
        &:not(&:nth-child(1)) {
          margin-left: 1rem;
        }
        & > img {
          margin-left: 0.5rem;
        }
      }
    }
    & > h4 {
      margin: 2rem 0 1rem;
      font-weight: 600;
      font-size: 1.25rem;
      color: inherit;
      @media screen and (min-width: 1200px) {
        font-size: 1.5rem;
      }
    }
    .platform-list {
      display: flex;
      flex-wrap: wrap;
      column-gap: 1.5rem;
      row-gap: 0.5rem;
      .item-platform {
        display: flex;
        flex-flow: column;
        align-items: center;
        background-color: rgba(207, 235, 232, 0.6);
        padding: 9px 16px;
        border-radius: 8px;
        font-weight: bolder;
        font-size: 20px;
        color: #0b998e;
        // &:not(&:nth-child(1)) {
        //   margin-left: 1.5rem;
        // }
        img {
          max-width: 4rem;
          max-height: 4rem;
        }
        p {
          margin: 0;
          font-size: 1rem;
        }
      }
    }
    .img-gallery-container {
      border-radius: 0.75rem;
      overflow: hidden;
      & > img {
        width: 100%;
      }
    }
  }
}

.prototype-container {
  display: flex;
  gap: 10px;
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 40px;

  @media screen and (min-width: 1200px) {
    display: flex;
    max-width: 1200px;
    padding-left: 0;
    padding-right: 0;
  }

}

.project-prototype {
  display: grid;
  gap: 10px;
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;

  h4 {
    font-size: 18px;
    line-height: 1.4;
    margin-bottom: 0;
    margin-top: 10px;
  }
  
  @media screen and (min-width: 1200px) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    max-width: 1200px;
    padding-left: 0;
    padding-right: 0;
  }

  .project-prototype-item {
    padding: 10px;
    border: thin solid #ddd;
    border-radius: 10px;
    display: grid;
    grid-template-columns: 100px 1fr auto;
    gap: 10px;

    a {
      text-decoration: underline;
    }

    .btn-copy {
      width: 36px;
      height: 36px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: thin solid #ddd;
      border-radius: 4px;
      background-color: white;
      &:hover {
        background-color: #f3f3f3;
      }
    }

    .preview {
      width: 100%;
      height: 100px;
      overflow: hidden;
      border-radius: 5px;
    }

    .content {
      display: grid;
      grid-template-columns: 1fr auto;
      align-items: center;
    }

    .label {
      opacity: .5;
      margin-top: 10px;
    }

    @media screen and (min-width: 1200px) {
      padding: 20px;
      display: block;

      .preview {
        width: 100%;
        height: 200px;
      }
    }
  }
}