@import "~antd/dist/antd.less";
@import "./colors.less";
@import "./layout.less";
@import "./homepage.less";
@import "./about.less";
@import "./project.less";
@import "./career.less";

@import "./responsive.less";

* {
  font-family: "Work Sans", sans-serif;
  font-weight: 400;
}

iframe {
  display: none;
}

button {
  cursor: pointer;
}

.ant-layout {
  background-color: @white;
}

.ant-layout-header {
  background: #ffffff;
  line-height: 50px;
}

#components-layout-demo-top .logo {
  float: left;
  width: 120px;
  height: 31px;
  margin: 16px 24px 16px 0;
  background: rgba(255, 255, 255, 0.3);
}
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected {
  color: #0b998e;
  font-weight: bold !important;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover {
  color: #0b998e;
  font-weight: bold !important;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after {
  border-bottom: 2px solid #0b998e;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after {
  border-bottom: 2px solid #0b998e;
}

.ant-menu-horizontal {
  border-bottom: none;
}

.ant-menu-horizontal > .ant-menu-item::after,
.ant-menu-horizontal > .ant-menu-submenu::after {
  border-bottom: none;
}

.ant-collapse-item {
  border: 1px solid @ash500 !important;
  border-radius: 20px !important;
  padding: 15px;
  margin-bottom: 20px;

  .ant-collapse-header {
    color: @charcoal800;
    font-weight: 700;
    font-size: 20px;
    line-height: 150%;
  }

  p {
    font-size: 16px;
    line-height: 150%;
  }
}

.ant-collapse-item-active {
  .ant-collapse-header {
    color: @primary !important;
  }
}

.ant-row-rtl #components-layout-demo-top .logo {
  float: right;
  margin: 16px 0 16px 24px;
}

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {
  // background: transparent;
  background: #eeeeee;
}

::-webkit-scrollbar-thumb {
  // background: #748494;
  background: #cacacc;
}

::-webkit-scrollbar-thumb:hover {
  // background: #748494;
  background: #adadaf;
}

.ant-modal-confirm-btns {
  display: flex;
}

.ant-image-img {
  border-radius: 10px;
  object-fit: cover;
}

.homepage-menu {
  a {
    color: @black;
  }
}

.footer-section {
  a {
    color: @black;
  }
}

.ant-collapse-borderless {
  .ant-collapse-item {
    background: #ffffff;
  }
}

.ant-modal {
  top: 0;
  margin: 24px auto;
  padding-bottom: 0;
}

.ant-modal-header {
  border-bottom: none;
  padding-bottom: 5px;
}

.drawer-menu {
  a {
    color: @black;
  }
}

.user-menu {
  a {
    color: @black;
  }
}

@primary-color: #0B998E;@border-radius-base: 5px;@text-selection-bg: rgba(0,0,0,0.3);@menu-item-active-bg: #0B998E;@menu-highlight-color: #FFFFFF;