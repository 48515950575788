@import "./colors.less";

.layout-content {
  margin: 0 auto;
}

header {
  width: 100%;
}

.layout-header {
  padding-top: 10;
  padding-bottom: 60;
  box-shadow: "0px 1px #f2f2f2";
  z-index: 10;
}

.header-homepage-wrapper {
  position: absolute;
  width: 100%;
  border-bottom: 1px solid #d9d9d9;
}

.header-homepage {
  display: flex;
  margin: 0 auto;
  align-items: center;
  justify-content: space-between;
  height: 80px;
  width: 100%;

  .menu {
    flex: 1;

    .ant-menu {
      background-color: transparent;
    }
  }
}

.footer-container {
  position: relative;
  background-color: @white;
  width: 100%;
  padding-top: 100px;
  padding-bottom: 100px;
  overflow: hidden;
  & > span {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    content: "";
    &:before {
      position: absolute;
      content: "";
      top: 62%;
      left: 0;
      width: 45px;
      height: 45px;
      transform: translateX(-30%);
      border-radius: 50%;
      background: #cfebe8;
      @media screen and (min-width: 1200px) {
        top: 30%;
      }
    }
    &::after {
      position: absolute;
      content: "";
      top: 70%;
      left: 0;
      width: 278px;
      height: 278px;
      border-radius: 50%;
      background: #cfebe8;
      transform: translateX(-60%);
      @media screen and (min-width: 1200px) {
        top: 40%;
        width: 548px;
        height: 548px;
      }
    }
  }
}

.site-layout-content {
  // min-height: 280px;
  padding-top: 24px;
  padding-bottom: 24px;
  padding-left: 1rem;
  padding-right: 1rem;
  // background: @white;
  margin: 0 auto;
}

.site-layout-content-bg-edge {
  min-height: 280px;
  padding-top: 24px;
  padding-bottom: 24px;
  margin: 0 auto;
}

.footer-layout-content {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  @media screen and (min-width: 768px) {
    padding: 0 92px;
  }
  @media screen and (min-width: 1200px) {
    flex-direction: row;
    justify-content: space-between;
  }
  .section-left {
    width: 420px;
    max-width: 100%;
  }
  .section-center {
    display: flex;
    flex-direction: column;
    max-width: 100%;
  }
  .section-right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 100%;
  }
  .footer-nav {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .title {
    margin: 24px 0 12px;
    font-size: 1.5rem;
  }
  .desc {
    color: @black70;
    margin: 0;
  }
  .visit-us-on {
    font-size: 1.25rem;
    margin: 24px 0 5px;
  }
  .section-head {
    font-size: 25px;
    font-weight: 500;
    margin: 32px 0;
  }
  .items-container {
    display: flex;
    flex-wrap: wrap;
    gap: 22px;
  }
  .item-container {
    color: #404041;
    margin-top: 18px;
    display: flex;
    column-gap: 15.6px;
    .icon {
      width: 21px;
      height: 21px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .icon-large {
      width: 36px;
      height: 36px;
    }
  }
  .menu-link {
    display: block;
    margin: 0;
    font-weight: 300;
    color: @black;
    letter-spacing: -0.02em;
    white-space: nowrap;
  }
}

.section-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;

  .section-left {
    // flex: 1;

    h3 {
      font-weight: 700;
      font-size: 40px;
      margin-bottom: 0px;
    }

    p {
      color: @charcoal400;
      font-size: 16px;
    }
  }

  .section-right {
    flex-shrink: 1;
  }
}

// >Intro-banner
.intro-banner {
  padding: 3rem 0rem;
  background: @white;
  @media screen and (min-width: 1200px) {
    padding-top: 10rem;
    // padding-top: 8rem;
  }
  .content {
    padding-left: 1rem;
    padding-right: 1rem;
    color: @black70;
    font-size: 1rem;
    @media screen and (min-width: 1200px) {
      margin: 0 auto;
      max-width: 80%;
    }
    h4 {
      margin: 0 auto;
      color: inherit;
      font-weight: 600;
      text-align: center;
      font-size: 1.25rem;
      @media screen and (min-width: 1200px) {
        font-size: 1.5rem;
      }
    }
    h3 {
      color: @primary;
      font-weight: 600;
      text-align: center;
      font-size: 2.25rem;
      line-height: 2.75rem;
      // margin: 1rem 0 0;
      @media screen and (min-width: 1200px) {
        // margin-top: 1.5rem;
        font-size: 3rem;
        line-height: 3.5rem;
      }
    }
    p {
      color: inherit;
      font-size: 20px;
      text-align: center;
      margin: 0.5rem auto 0;
      // @media screen and (min-width: 1200px) {
      //   margin-top: 0.5rem;
      // }
    }
  }
}

.spin-container {
  padding: 2rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.masonry-list {
  display: flex;
  margin-left: -1rem;
  width: auto;
  .masonry-column {
    padding-left: 1rem;
    background-clip: padding-box;
    & > div {
      margin-bottom: 1rem;
    }
  }
}

.lets-talk-drawer {
  margin: 2rem 0 0;
  p {
    margin: 0;
    color: @primary;
    font-size: 1rem;
    text-align: center;
  }
  .btn {
    width: 100%;
    margin: 0.5rem 0 0;
  }
}
