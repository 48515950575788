@media (min-width: 1536px) {
  .footer-wrapper,
  .header-homepage,
  .site-layout-content {
    max-width: 1436px;
  }
  .footer-layout-content {
    max-width: 1440px;
  }
}

@media (min-width: 1200px) and (max-width: 1535px) {
  .footer-wrapper,
  .header-homepage,
  .site-layout-content {
    max-width: 1100px;
  }
  .footer-layout-content {
    max-width: 1100px;
  }

  .footer-content {
    // display: grid;
    // grid-template-columns: 1.6fr repeat(3, 1fr);
    // grid-template-rows: none;
    // align-items: flex-start;
    display: flex;
    align-items: flex-end;
  }

  .footer-section {
    margin-bottom: 0;
  }
  .footer-wrapper {
    .footer-section {
      &:nth-child(1) {
        flex: 1;
      }
      &:nth-child(2) {
        width: 50%;
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 1200px) {
  .footer-wrapper,
  .header-homepage,
  .site-layout-content {
    max-width: 768px;
  }
  .footer-layout-content {
    max-width: 768px;
  }

  .header-web {
    padding: 0 40px;
  }

  .header-web {
    flex-direction: column;
    padding: 0 20px;

    .ant-col {
      max-width: 100%;
    }

    img {
      width: 100%;
    }

    .header-action-wrapper {
      padding: 16px 24px 16px 16px;
      box-shadow: 0px 12px 24px -8px rgba(0, 0, 0, 0.15),
        0px 1px 1px rgba(0, 0, 0, 0.06);
      border-radius: 100px;
      background-color: @white;
      margin-bottom: 20px;
    }

    .action-btn-wrapper {
      width: 100%;
    }

    .content {
      width: 100%;
    }

    .btn-action {
      width: 100%;
      height: 52px;
    }
  }

  .header-homepage {
    padding: 0 20px;
    .btn-sidebar {
      order: 2;
      border: none;
      background-color: transparent;
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .btn-menu {
      border: none;
      background-color: @primary;
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 10px;
    }
    .logo-wrapper {
      // flex: 1;
      order: 1;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .homepage-menu-tablet {
      width: auto;
    }
  }

  .homepage-section {
    margin: 80px 40px;
  }

  .footer-wrapper {
    max-width: 640px;
  }

  .footer-content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(1, 1fr);
    align-items: flex-start;
    gap: 80px;
  }

  .how-container {
    gap: 80px;
  }

  .how-image-wrapper {
    height: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: center;

    img {
      width: 350px;
      height: 350px;
      object-fit: contain;
    }
  }

  .location-list-container {
    max-width: 574px;
  }

  .location-card {
    display: inline-table;
  }
}

@media (max-width: 767px) {
  .footer-wrapper,
  .header-homepage,
  .site-layout-content {
    max-width: 100%;
  }
  .footer-layout-content {
    max-width: 100%;
  }

  .header-homepage {
    padding: 0 20px;
    .btn-sidebar {
      order: 2;
      border: none;
      background-color: transparent;
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .btn-menu {
      border: none;
      background-color: @primary;
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 10px;
    }
    .logo-wrapper {
      // flex: 1;
      order: 1;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .homepage-menu-tablet {
      width: auto;
    }
  }

  .header-web {
    flex-direction: column;
    padding: 0 20px;

    .ant-col {
      max-width: 100%;
    }

    img {
      width: 100%;
    }

    .header-action-wrapper {
      padding: 16px 24px 16px 16px;
      box-shadow: 0px 12px 24px -8px rgba(0, 0, 0, 0.15),
        0px 1px 1px rgba(0, 0, 0, 0.06);
      border-radius: 100px;
      background-color: @white;
      margin-bottom: 20px;
    }

    .action-btn-wrapper {
      width: 100%;
    }

    .content {
      width: 100%;
    }

    .btn-action {
      width: 100%;
      height: 52px;
    }
  }

  .homepage-section {
    margin: 80px 20px;
  }

  .section-odd {
    margin-left: 20px;
    margin-right: 20px;
  }

  .homepage-section-top-pick {
    margin-left: 0px;
    margin-right: 0px;
  }

  .how-container {
    grid-template-columns: none;
    grid-template-rows: repeat(3, 1fr);
    gap: 40px;

    .how-image-wrapper {
      height: auto;
      img {
        height: 100%;
        width: 100%;
      }
    }
  }

  .how-item {
    display: flex;
    flex-direction: column;
    align-items: center;

    .ant-col {
      width: 100%;
      max-width: 100%;
    }

    .how-index {
      display: none;
    }

    .how-title {
      text-align: center;
      font-size: 32px;
      margin-bottom: 20px;
      margin-top: 40px;
    }

    .how-description {
      text-align: center;
    }
  }

  .location-card {
    display: inline-table;

    margin-right: 0;
    width: 100%;
    border: none;
  }

  .location-card-active {
    border: 1px solid @primary;
  }

  .how-image-wrapper {
    width: 100%;
  }

  .footer-container {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .footer-wrapper {
    max-width: 100%;
  }

  .footer-content {
    display: block;
  }

  .footer-section {
    margin-bottom: 50px;
  }

  .footer-social-media-wrap {
    display: flex;
  }

  .bottom-banner-wrapper {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 30px 30px;
    background-position-x: -20px;
    height: auto;

    h1 {
      font-size: 36px;
      line-height: 120%;
      font-weight: 800;
      color: @white;
      max-width: 100%;
      margin-bottom: 40px;
    }

    .bottom-banner-link {
      display: flex;
      align-items: center;
      background-color: @white;
      border-radius: 100px;
      padding: 12px 24px;
      color: @primary;

      span {
        font-weight: 700;
        font-size: 18px;
      }

      img {
        margin-right: 10px;
      }
    }
  }
}
