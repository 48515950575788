@import "./colors.less";

.bg-header-homepage {
  background-image: url("./images/header-background.png");
  background-repeat: no-repeat;
  background-position-x: right;
  background-size: 1100px;
  background-position-y: -130px;
}

.header-homepage-wrapper {
  position: fixed;
  z-index: 99;
  border-bottom: none;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;
}

.header-homepage-white {
  background: white;
  border-bottom: 1px solid #d9d9d9;
}

.header-homepage {
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .logo-wrapper {
    img {
      max-width: 80px;
    }
  }

  .homepage-menu {
    // width: 60%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    a {
      padding: 15px 0;
      color: @white;
    }
  }
}

.banner-image {
  object-fit: scale-down;
  width: 500px;
}

.header-action-container {
  display: grid;
  gap: 15px;
  align-items: center;
  grid-template-columns: 1fr 2px 1fr 120px;
  padding: 16px;
  box-shadow: 0px 12px 24px -8px rgba(0, 0, 0, 0.15),
    0px 1px 1px rgba(0, 0, 0, 0.06);
  border-radius: 100px;
  background-color: @white;
}

.separator-vertical {
  width: 1px;
  height: 100%;
  background-color: @ash400;
}

.btn-action {
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: @primary;
  color: @white;
  border-radius: 100px;
  border: none;
  font-size: 16px;
  font-weight: 700;
}

.action-btn-wrapper {
  display: flex;
  align-items: center;
  cursor: pointer;

  .icon {
    width: 42px;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    border: 1px solid @ash400;
  }

  .content {
    margin-left: 10px;
    flex: 1;

    .label {
      font-size: 14px;
      line-height: 150%;
      color: @charcoal400;
    }
    .value-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .value {
      font-size: 16px;
      line-height: 150%;
      font-weight: 700;
      color: @black;
    }
  }
}

.homepage-section {
  margin: 150px 0;
}

.slider-actions {
  display: flex;
  align-items: center;

  button:last-child {
    margin-left: 10px;
  }

  button {
    width: 42px;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: @primary;
    border-radius: 100px;
    border: none;
    transition: 500ms all ease;
  }

  button:hover {
    opacity: 0.7;
  }
}

.top-pick-card {
  position: relative;
  margin-right: 25px;

  cursor: pointer;

  span {
    position: absolute;
    right: 15px;
    top: 15px;
    padding: 5px 16px;
    background-color: @white;
    color: @black;
    font-weight: 700;
    border-radius: 100px;
  }

  img {
    border-radius: 20px;
    width: 100%;
    height: 180px;
    object-fit: cover;
    margin-bottom: 15px;
  }

  h4 {
    font-size: 20px;
    font-weight: 500;
    color: @black;
  }

  p {
    color: @charcoal400;
  }
}

.how-container {
  display: grid;
  grid-template-rows: repeat(3, 1fr);
  gap: 100px;
}

.how-index {
  width: 50px;
  height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #14b5b0;
  border-radius: 15px;
  font-weight: 700;
  font-size: 24px;
  line-height: 120%;
  color: @white;
  margin-bottom: 40px;
}

.how-title {
  font-weight: 700;
  font-size: 38px;
  line-height: 120%;
  color: @charcoal800;
  margin-bottom: 40px;
}

.how-description {
  font-size: 16px;
  line-height: 200%;
  color: @charcoal800;
}

.how-image-wrapper {
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;

  img {
    width: 400px;
    height: 400px;
    object-fit: contain;
  }
}

.location-slider-wrapper {
  margin-top: 40px;
  display: flex;
  align-items: center;
}

.btn-slick {
  width: 42px;
  height: 42px;
  background-color: @primary;
  border-radius: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
}

.location-list-container {
  width: 986px;
}

.location-card {
  padding: 20px;
  height: 153px;
  border-radius: 15px;
  margin-right: 20px;
  border: 1px solid @ash400;
  cursor: pointer;

  h4 {
    font-weight: 700;
    font-size: 20px;
    line-height: 150%;
    color: @charcoal800;
  }

  p {
    margin-top: 10px;
    line-height: 150%;
    color: @charcoal400;
  }
}

.location-card-active {
  border: 1px solid @primary;
}

.faqs-link-container {
  margin-top: 40px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.faqs-link {
  padding: 15px 30px;
  background-color: @primary;
  border-radius: 100px;
  color: @white;
  font-size: 20px;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
}

.header-web {
  position: relative;
}

.bottom-banner-wrapper {
  border-radius: 36px;
  background-color: @primary;
  max-width: 1100px;
  height: 300px;
  margin: 0 auto;
  background-image: url("./images/bg-banner-bottom.png");
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 80px;
  background-position-x: 200px;
  background-position-y: top;

  h1 {
    font-size: 42px;
    line-height: 120%;
    font-weight: 800;
    color: @white;
    max-width: 60%;
  }

  .bottom-banner-link {
    display: flex;
    align-items: center;
    background-color: @white;
    border-radius: 100px;
    padding: 12px 24px;
    color: @primary;

    span {
      font-weight: 700;
      font-size: 18px;
    }

    img {
      margin-right: 10px;
    }
  }
}

.drawer-nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.drawer-menu {
  margin-top: 40px;
  display: flex;
  flex-direction: column;

  a {
    font-size: 16px;
    margin-bottom: 15px;
  }
}
.user-info-wrapper {
  display: flex;
  align-items: center;

  .user-info {
    margin-left: 10px;
  }

  span {
    color: @ash800;
  }
}

.user-menu {
  display: flex;
  flex-direction: column;
  a {
    margin-top: 15px;
    font-size: 16px;
  }
}

.user-info-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
}

.user-info-signin-wrapper {
  text-align: center;
}

.menu-footer-container {
  position: absolute;
  bottom: 20px;
  width: 85%;
}

.btn {
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 42px;
  padding: 8px 16px;
  border: none;
  background-color: @primary;
  color: @white;

  // span {
  //   margin-left: 10px;
  // }
}

.btn-outlined {
  border: 1px solid @primary;
  background-color: @white;
  color: @primary;
}

// =============HOME=============

// universal
.header-content {
  margin: auto;
  h2 {
    margin: 0;
    color: @primary;
    font-size: 2rem;
    font-weight: 600;
    text-align: center;
    @media screen and (min-width: 1200px) {
      font-size: 39px;
    }
  }
  p {
    margin: 2px 0 0;
    color: #79797a;
    font-size: 16px;
    text-align: center;
    // @media screen and (min-width: 1200px) {
    //   font-size: 1.5rem;
    // }
  }
}

// >MainBanner
.main-banner-home {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  .start-scroll {
    content: "";
    position: absolute;
    width: 0.3rem;
    height: 71px;
    border-radius: 13px;
    background: @white;
    bottom: 3%;
    left: 50%;
    transform: translateX(-50%);
  }
  .content {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: @white;
    font-size: 1rem;
    h1 {
      color: inherit;
      font-size: 2.25rem;
      font-weight: 600;
      font-weight: 700;
      // line-height: 5rem;
      text-align: center;
      margin: 0;
      @media screen and (min-width: 768px) {
        font-size: 2.5rem;
        line-height: 3.5rem;
      }
      @media screen and (min-width: 1200px) {
        font-size: 64px;
        line-height: 78.4px;
      }
      span {
        display: block;
      }
    }
    p {
      color: inherit;
      margin: 0.5rem 0 0;
      font-size: 16px;
      font-weight: 400;
      text-align: center;
      @media screen and (min-width: 768px) {
      }
      @media screen and (min-width: 1200px) {
        line-height: 19.2px;
      }
    }
    .btn-start-project {
      margin: 2.5rem 0 0;
      padding: 25px 35px !important;
    }
  }
}

// >Services
.services-home {
  margin: 4rem auto 0;
  .header-content {
    @media screen and (min-width: 1200px) {
      max-width: 60%;
    }
  }
  .content {
    margin: 48px auto 0;
    width: fit-content;
    padding: 0 1rem 1rem;
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    row-gap: 14px;
    column-gap: 56px;
    @media screen and (min-width: 576px) {
      padding: 0;
      row-gap: 24px;
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }
    @media screen and (min-width: 768px) {
      padding: 0;
      row-gap: 24px;
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    @media screen and (min-width: 1200px) {
      padding: 0;
      row-gap: 24px;
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }
    .item-card {
      margin: 0 auto;
      width: 100%;
      // height: 420px;
      padding: 32px;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      row-gap: 18px;
      border-style: solid;
      border-width: 2px;
      border-color: #d9d9d9;
      border-radius: 8px;
      // transition-property: box-shadow;
      // transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
      // transition-duration: 300ms;
      @media screen and (min-width: 576px) {
        width: 342px;
      }
      &:hover {
        border-color: #009083;
        // box-shadow: 0px 12px 36px -9px rgba(0, 0, 0, 0.1);
        // .item-icon-container {
        // background-color: @primary20;
        // }
      }
      .item-icon-container {
        // width: 76px;
        // height: 76px;
        // display: flex;
        // justify-content: center;
        // align-content: center;
        // border-radius: 14px;
        // padding: 16px;
        // transition-property: background-color;
        // transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
        // transition-duration: 300ms;
        .item-icon {
          object-fit: contain;
        }
      }
      .item-title {
        font-size: 1.4rem;
        font-weight: 600;
        margin: 0;
        color: @charcoal700;
      }
      .item-experiences-title {
        font-size: 1.25rem;
        margin: 0;
        color: @charcoal400;
      }
      .item-experiences-list {
        font-size: 1.1rem;
        height: 160px;
        margin: 0;
        text-align: left;
        padding-left: 1.15rem;
        @media screen and (min-width: 768px) {
          padding: 0;
          row-gap: 46px;
          font-size: 1.25rem;
        }
        & > li {
          font-weight: 400;
          font-size: 20px;
          color: @charcoal500;
        }
      }
    }
  }
}

// >Projects
.projects-home {
  margin: 1.5rem auto 0;
  @media screen and (min-width: 768px) {
    margin: 3rem auto 0;
  }
  .header-content {
    @media screen and (min-width: 1200px) {
      max-width: 60%;
    }
  }
  .grid-container {
    margin-top: 48px;
    padding: 0 10px;
    display: flex;
    flex-wrap: wrap;
    @media screen and (min-width: 1200px) {
      display: grid;
      gap: 16px;
      grid-template-columns: repeat(3, minmax(0, 1fr));
      grid-template-rows: repeat(4, minmax(0, 1fr));
    }
    .item-container {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      // height: 200px;
      height: 250px;
      width: 100%;
      @media screen and (min-width: 576px) {
        width: 50%;
      }
      @media screen and (min-width: 1200px) {
        width: 100%;
        max-width: 1024px;
      }
      // .slick-track {
      //   height: 100%;
      //   .slick-slide {
      //     position: relative !important;
      //     top: 0;
      //     right: 0;
      //     bottom: 0;
      //     left: 0;
      //   }
      // }
      .carousel-img {
        object-fit: cover;
        object-position: center;
        height: 100%;
        width: 100%;
      }
      .item-overlay {
        position: absolute;
        opacity: 0;
        top: 0;
        width: 100%;
        padding-top: 30px;
        height: calc(100% + 30px);
        transition-property: opacity, transform;
        transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
        transition-duration: 300ms;
        &:hover {
          opacity: 1;
          transform: translateY(-30px);
        }
        .bottom-info {
          width: 100%;
          height: 100%;
          position: absolute;
          bottom: 0;
          background-color: rgba(0, 0, 0, 0.75);
          padding: 24px;
          height: fit-content;
          @media screen and (min-width: 576px) {
            display: flex;
            flex-direction: column;
            height: fit-content;
          }
          .item-info {
            display: flex;
            flex-direction: column;
            row-gap: 12px;
            margin-bottom: 15px;
            .item-info-desc {
              overflow: hidden;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 3;
            }
            h4 {
              color: @white;
              font-size: 2rem;
              font-weight: 700;
              // margin-bottom: 15px;
              margin: 0;
              padding: 0;
            }
            p {
              color: @white;
              font-weight: 400;
              // margin-bottom: 15px;
            }
          }
          .item-info-large {
            max-width: 65%;
          }
          .platforms {
            display: none;
            @media screen and (min-width: 768px) {
              display: flex;
              gap: 8px;
            }
            .platform {
              height: fit-content;
              background-color: @white;
              color: @primary;
              border-radius: 100px;
              padding: 5px 15px;
              font-weight: 400;
              font-size: 12px;
            }
          }
          .btn-platform-container {
            column-gap: 6px;
            flex-shrink: 0;
            display: flex;
            justify-content: space-between;
            align-items: center;
          }
          .detail-button {
            display: block;
            width: fit-content;
            color: @white;
            background-color: @primary;
            border-radius: 8px;
            padding: 14px 16px;
            font-weight: 600;
          }
        }
        .bottom-info-large {
          @media screen and (min-width: 768px) {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            gap: 40px;
          }
        }
      }
    }
    .item-container-large {
      // height: 416px;
      height: 250px;
      width: 100%;
      max-width: 1024px;
      @media screen and (min-width: 576px) {
        grid-column: span 2 / span 2;
        grid-row: span 2 / span 2;
        height: 516px;
      }
    }
  }
  .see-projects-container {
    padding: 0 10px;
    .see-projects-link {
      margin: 20px auto 0;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 14px 16px;
      gap: 10px;
      border: 2px solid @primary;
      border-radius: 8px;
      @media screen and (min-width: 768px) {
        width: fit-content;
      }
    }
  }
}

// >Clients
.clients-home {
  margin: 4rem auto 0;
  padding-left: 1rem;
  padding-right: 1rem;
  @media screen and (min-width: 1200px) {
    padding-left: 0;
    padding-right: 0;
  }
  .header-content {
    @media screen and (min-width: 1200px) {
      max-width: 60%;
    }
  }
  .slider-container {
    margin: 0;
    width: 100%;
    padding-top: 45px;
    padding-bottom: 45px;
    .slick-slide {
      & > div {
        display: flex;
        justify-content: center;
        padding: 30px 0;
      }
      .client-item-container {
        max-width: 170px;
        .client-item-img {
          display: block;
          margin-left: auto;
          width: 100%;
          height: 100%;
          object-fit: contain;
          padding: 0 20px;
        }
      }
    }
    .slick-dots {
      margin: 0;
      bottom: 30px;
      display: none !important;
      @media screen and (min-width: 768px) {
        display: flex !important;
        bottom: 5%;
      }
      @media screen and (min-width: 1200px) {
        bottom: 4%;
      }

      li {
        width: auto;
        padding-left: 4px;
        padding-right: 4px;
        button {
          border-radius: 50%;
          width: 12px;
          height: 12px;
          border: 1px solid @secondary;
          &::before {
            display: none;
          }
        }
      }
      .slick-active {
        button {
          background-color: @secondary;
        }
      }
    }
  }
  .content {
    margin: 2.5rem 0 0;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    & > div {
      width: 33.33%;
      padding: 0.5rem;
      @media screen and (min-width: 768px) {
        width: 20%;
      }
      img {
        max-width: 100%;
      }
    }
  }
}

// >WeLove
.we-love-bg-edge {
  background: @primary;
  .we-love-home {
    margin: 4rem auto 0;
    padding: 0 1rem;
    @media screen and (min-width: 1200px) {
      padding: 0;
    }
    .we-love-container {
      position: relative;
      background: @primary;
      overflow: hidden;
      padding: 5rem 1rem;
      @media screen and (min-width: 1200px) {
        padding: 6rem 5rem;
      }
      & > span {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        content: "";
        &:before {
          position: absolute;
          content: "";
          top: 30%;
          left: 0;
          width: 45px;
          height: 45px;
          transform: translateX(-30%);
          border-radius: 50%;
          background: #009083;
          @media screen and (min-width: 1200px) {
            top: 15%;
          }
        }
        &::after {
          position: absolute;
          content: "";
          top: 50%;
          left: 0;
          width: 278px;
          height: 278px;
          border-radius: 50%;
          background: #009083;
          transform: translateX(-60%);
          @media screen and (min-width: 1200px) {
            top: 35%;
            width: 326px;
            height: 326px;
            transform: translateX(-25%);
          }
        }
        &:nth-child(2) {
          transform: rotate(180deg);
        }
      }
      .content-container {
        position: relative;
        display: flex;
        flex-flow: column;
        width: 100%;
        z-index: 1;
        @media screen and (min-width: 768px) {
          flex-flow: row wrap;
          align-items: center;
          justify-content: space-between;
        }
        .content {
          display: flex;
          flex-flow: column;
          align-items: center;
          @media screen and (min-width: 1200px) {
            flex-flow: row wrap;
          }
          img {
            max-width: 65px;
            @media screen and (min-width: 768px) {
              max-width: 80px;
            }
            @media screen and (min-width: 1200px) {
              max-width: 140px;
            }
          }
          h3 {
            margin: 1.5rem 0 0;
            flex: 1;
            font-size: 1rem;
            color: @white;
            font-weight: 600;
            text-align: center;
            @media screen and (min-width: 768px) {
              font-size: 1.25rem;
            }
            @media screen and (min-width: 1200px) {
              margin: 0 0 0 0.5rem;
              text-align: left;
              font-size: 1.5rem;
            }
            @media screen and (min-width: 1535) {
              font-size: 1.75rem;
            }
            span {
              display: block;
            }
          }
        }
        .action-btn-container {
          margin: 1.5rem 0 0;
          @media screen and (min-width: 768px) {
            margin: 0 0 0 1rem;
            width: 45%;
          }
          @media screen and (min-width: 1200px) {
            width: 35%;
          }
          & > button {
            width: 100%;
            color: @primary;
          }
          & > div {
            margin: 1.25rem 0 0;
            display: flex;
            justify-content: space-between;
            & > button {
              width: 48%;
              border: 2px solid @white;
              display: flex;
              align-items: center;
              & > span {
                &:nth-child(1) {
                  order: 1;
                  margin-left: 8px;
                }
                &:nth-child(2) {
                  order: 0;
                  margin: 0;
                }
              }
            }
          }
        }
      }
    }
  }
}

.modal-lets-talk {
  .content {
    padding: 4rem 1rem 1rem;
    color: @black70;
    h3 {
      margin: 0 0 2.125rem;
      color: @primary;
      font-size: 2rem;
      line-height: 2.5rem;
      font-weight: 600;
      text-align: center;
      @media screen and (min-width: 1200px) {
        font-size: 31px;
      }
    }
    form {
      .form-input-container {
        margin: 0;
        &:not(&:nth-child(1)) {
          margin-top: 1rem;
        }
      }
      .ant-form-item-label {
        padding: 0;
        line-height: normal;
      }
      label {
        display: block;
        font-size: 0.75rem;
        padding: 0;
        margin: 0 0 0.25rem;
        color: @black70;
        &.label-input {
          margin-top: 1rem;
        }
      }
      .tag-services {
        display: flex;
        flex-flow: row wrap;
        gap: 0.5rem 0.05rem;
        & > span {
          border: 1px solid @black70;
        }
      }
    }
  }
}
